/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-12-15 17:07:42
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'recommendCode',
    itemProps: {
      label: '当前推荐手机号：' },

    component: {
      name: 'text' } },


  {
    kname: 'recommendName',
    itemProps: {
      label: '当前推荐人姓名：' },

    component: {
      name: 'text' } },


  {
    kname: 'newRecommendCode',
    itemProps: {
      label: '绑定新手机号：',
      rules: [genRules('require'), genRules('phone')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 11 } } }];});





// form 默认值
export var formData = function formData() {return {
    li: [{}, {}, {}, {}, {}, {}, {}, {}] };};